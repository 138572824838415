import React from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { TextField } from './forms'
import { Label, Button } from './ui'

import type { RemindPasswordForm, FormSubmitFn } from '../types'

interface ForgotPasswordProps {
    isSuccess: boolean
    handleSubmit: FormSubmitFn<RemindPasswordForm>
}

export default function ForgotPassword({
    isSuccess,
    handleSubmit,
}: ForgotPasswordProps) {
    const { t } = useTranslation()

    if (isSuccess) {
        return (
            <div className="">
                <h1 className="">{t('auth.remind.success.title')}</h1>
                <p className="">{t('auth.remind.success.subtitle')}</p>
            </div>
        )
    }

    return (
        <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
                <Form className="flex flex-col items-center gap-12">
                    <div className="text-center text-lg leading-6 font-normal text-gray-400 w-full max-w-[296px] lg:max-w-full">
                        <h1>{t('auth.remind.title')}</h1>
                        <p>{t('auth.remind.subtitle')}</p>
                    </div>

                    <div className="w-full max-w-[296px] lg:max-w-[576px]">
                        <Label>{t('auth.email')}</Label>
                        <TextField name="email" />
                    </div>

                    <div className="w-full max-w-[296px] lg:max-w-[576px]">
                        <Button
                            variant="primary"
                            type="submit"
                            size="xl"
                            className="w-full"
                            loading={isSubmitting}
                        >
                            {t('auth.remind.send')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
