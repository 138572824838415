import classNames from 'classnames'
import React from 'react'

const CheckboxElement: React.FC<{
    name: string
    value: boolean
    onChange: (value: boolean) => void
}> = ({ name, value, onChange }) => {
    return (
        <div className="relative flex items-start">
            <div className="flex h-5 items-center relative">
                <input
                    id={name}
                    name={name}
                    type="checkbox"
                    value={value ? '1' : '0'}
                    className={classNames('appearance-none h-4 w-4 rounded', {
                        'border-red-700 bg-red-600': value,
                        'border-gray-400 bg-gray-300': !value,
                    })}
                    onChange={() => onChange(!value)}
                />
                {value && (
                    <span
                        className="absolute text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        onClick={() => onChange(!value)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3.5 w-3.5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            stroke="currentColor"
                            stroke-width="1"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </span>
                )}
            </div>
            <div className="ml-3 h-5 text-sm leading-5">
                <label htmlFor={name} className="font-normal text-white">
                    {name}
                </label>
            </div>
        </div>
    )
}

export default CheckboxElement
