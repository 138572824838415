import React from 'react'
import pl from 'date-fns/locale/pl'
import enGB from 'date-fns/locale/en-GB'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid'
import { DateRange } from 'react-day-picker'
import { Options as FocusTrapOptions } from 'focus-trap'
import {
    Locale,
    isMonday,
    isTuesday,
    isWednesday,
    isThursday,
    isFriday,
    isSaturday,
    isValid,
} from 'date-fns'
import type {
    ClassNames,
    Formatters,
    PropsBase,
    ModifiersClassNames,
} from 'react-day-picker'

import styles from '../DatePicker.module.scss'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { ChevronDownIcon } from '@heroicons/react/outline'

const DATE_REGEX = new RegExp('^[0-3][0-9].[0-1][0-9].([1-2]\\d{3})$')

interface DatePickerConfig {
    locale: Locale
    modifiers: PropsBase['modifiers']
    modifiersClassNames: ModifiersClassNames
    classNames: Partial<ClassNames>
    components: PropsBase['components']
    formatters: Partial<Formatters>
}

const useFormatWeekdayName: () => Formatters['formatDay'] = () => {
    const { t } = useTranslation()

    return (day) => {
        if (isMonday(day)) return t('day.monday')
        if (isTuesday(day)) return t('day.tuesday')
        if (isWednesday(day)) return t('day.wednesday')
        if (isThursday(day)) return t('day.thursday')
        if (isFriday(day)) return t('day.friday')
        if (isSaturday(day)) return t('day.saturday')

        return t('day.sunday')
    }
}

const useGetLocale: () => Locale = () => {
    const user = useCurrentUser()

    if (!user.data?.data.lang) {
        return enGB
    }

    switch (user.data.data.lang) {
        case 'pl':
            return pl
        case 'en':
            return enGB
        default:
            return enGB
    }
}

export const useGetDatePickerConfig = (
    blockedDays: Array<DateRange | Date>,
    modifiers?: PropsBase['modifiers']
): DatePickerConfig => {
    const formatWeekdayName = useFormatWeekdayName()
    const locale = useGetLocale()

    return {
        locale: locale,
        modifiers: { ...modifiers, unavailableDays: blockedDays },
        modifiersClassNames: {
            selected: styles.selected,
            today: styles.today,
            unavailableDays: styles.blockedDay,
            range_start: styles.rangeStart,
            range_end: styles.rangeEnd,
        },
        classNames: {
            root: styles.dayPickerRoot,
            // dropdown_root: styles.dayPickerRoot,
            caption_label: styles.captionLabel,
            day_button: styles.day,
            day: styles.cell,
            button_previous: styles.navButton,
            button_next: styles.navButton,
            range_start: styles.captionStart,
            range_end: styles.captionEnd,
            chevron: styles.navIcon,
            nav: styles.navigator,
            month_caption: styles.top,
            years_dropdown: styles.dropdown,
        },
        components: {
            Chevron: (props) => {
                if (props.orientation === 'left') {
                    return (
                        <ArrowLeftIcon className={styles.navIcon} {...props} />
                    )
                }
                if (props.orientation === 'down') {
                    return (
                        <ChevronDownIcon
                            className={styles.navIcon}
                            {...props}
                        />
                    )
                }
                return <ArrowRightIcon className={styles.navIcon} {...props} />
            },
        },
        formatters: { formatWeekdayName },
    }
}

export const getFocusTrapOptions = (
    onDeactivate: () => void
): FocusTrapOptions => ({
    initialFocus: false,
    allowOutsideClick: true,
    clickOutsideDeactivates: true,
    onDeactivate,
})

export const getDateFromUiDateString = (ddmmyyyFormat: string): Date | null => {
    if (ddmmyyyFormat.match(DATE_REGEX)) {
        const dateArr = ddmmyyyFormat.split('.')
        const date = new Date(`${dateArr[1]}.${dateArr[0]}.${dateArr[2]}`)

        if (isValid(date)) {
            return date
        }
    }

    return null
}
