import React, { useMemo } from 'react'
import classNames from 'classnames'
import { SearchBar } from './ui'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { ReactComponent as Logo } from '../assets/logo-inverse.svg'

export interface PageLayoutProps {
    className?: string
    renderTitle?: React.ReactElement
    title?: string
    children?: React.ReactElement
    actions?: React.ReactElement
    searchBarAction?: (value: string) => void
    breadCrumbs?: { name: string; url?: string }[]
}

export const PageLayout: React.FC<PageLayoutProps> = ({
    className,
    title,
    renderTitle,
    children,
    actions,
    searchBarAction,
    breadCrumbs,
}) => {
    const BreadCrumbs = useMemo(
        () =>
            breadCrumbs
                ?.map(({ name, url }, index) =>
                    url ? (
                        <Link
                            key={index}
                            to={url}
                            className="text-gray-400 text-sm leading-5 font-medium"
                        >
                            {name}
                        </Link>
                    ) : (
                        <span
                            key={index}
                            className="text-gray-400 text-sm leading-5 font-medium"
                        >
                            {name}
                        </span>
                    )
                )
                .flatMap((e, i) => [
                    e,
                    <span key={10 + i}>
                        <ChevronRightIcon className="w-5 h-5 text-gray-400" />
                    </span>,
                ])
                .slice(0, -1),
        [breadCrumbs]
    )

    return (
        <div
            className={classNames(
                'flex flex-col flex-1 pb-8 max-w-full print:p-0 ',
                className
            )}
        >
            <div className="print:p-0 px-6 lg:px-8">
                {!!searchBarAction ? (
                    <SearchBar action={searchBarAction} />
                ) : (
                    <div className="md:h-16 print:hidden"></div>
                )}
                <div className="flex items-center gap-4 mt-6 print:hidden">
                    {BreadCrumbs}
                </div>
                <div className="flex flex-col sm:flex-row">
                    <div className="flex-1 py-4">
                        <h2 className="text-2xl leading-8 font-semibold">
                            {!!renderTitle && renderTitle}
                            {!renderTitle && (
                                <span className="font-bold print:text-black">
                                    {title}
                                </span>
                            )}
                        </h2>
                    </div>
                    {!!actions && (
                        <div className="flex-1 py-4 print:hidden">
                            <div className="flex items-center sm:justify-end print:hidden">
                                {actions}
                            </div>
                        </div>
                    )}
                    <div className="flex-1 py-4 hidden print:block">
                        <div className="flex items-end justify-end">
                            <Logo className="h-8 w-auto" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col flex-1 max-w-full px-6 lg:px-8 print:p-0">
                {children}
            </div>
        </div>
    )
}

export default PageLayout
