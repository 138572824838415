import React, { useState, useMemo, useReducer, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import {
    DEFAULT_LIST_PER_PAGE,
    QUERY_CACHE_PATTERNS_KEY,
} from '../constants/index'
import { downloadPltFile, usePatternQuery, useUpdatePattern } from '../api'
import { listReducer } from '../helpers'
import type {
    PatternListFilterState,
    PatternListFilterAction,
    ListFilterAction,
    PatternForm,
    FormSubmitFn,
} from '../types'
import { useDeletePatternForm } from '../hooks/forms/useDeletePatternForm'
import { useFlashMessage, useModal } from '../hooks'
import { PatternResponse } from '../api/types'
import { usePolicy } from '../hooks/usePolicy'

import { FilterButton } from '../components/ui'
import PageLayout from '../components/PageLayout'
import PatternList from '../components/PatternList'
import PatternListFilters from 'components/PatternListFilters'
import ConfirmModal from '../components/ConfirmModal'
import PatternModal from '../components/PatternModal'

const filterReducer = (
    state: PatternListFilterState,
    action: PatternListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }
    if (action.type === 'setFilterBrand') {
        return {
            ...state,
            brand: action.payload,
            device: undefined,
            page: 1,
        }
    }
    if (action.type === 'setFilterDevice') {
        return {
            ...state,
            device: action.payload,
            page: 1,
        }
    }
    if (action.type === 'setFilterType') {
        return {
            ...state,
            type: action.payload,
            page: 1,
        }
    }
    if (action.type === 'setFilterSize') {
        return {
            ...state,
            size: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            size: undefined,
            type: undefined,
            device: undefined,
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as PatternListFilterState
}

export default function PatternListView() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const updatePatternModal = useModal<{
        id: number
        payload: PatternResponse
    }>()

    const downloadPltFileHandler = useCallback(
        async (pattern: PatternResponse) => {
            const response = await downloadPltFile(pattern.id)

            const href = URL.createObjectURL(
                new Blob([response.data], {
                    type: (response as any).headers['content-type'],
                })
            )

            const link = document.createElement('a')
            link.href = href
            link.setAttribute(
                'download',
                `${pattern.id} ${pattern.brand || ''} ${pattern.name}.plt`
            )
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(href)
        },
        []
    )

    const deletePatternForm = useDeletePatternForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_PATTERNS_KEY, 'index'])
    })

    const updatePattern = useUpdatePattern()
    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        device: undefined,
        brand: undefined,
        size: undefined,
        type: undefined,
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)

    const filterCount = useMemo(
        () =>
            (filters.device ? 1 : 0) +
            (filters.brand ? 1 : 0) +
            (filters.size ? 1 : 0) +
            (filters.type ? 1 : 0),
        [filters]
    )

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            search_global: filters.search_global || undefined,
            device_id: filters.device?.id || undefined,
            brand_id: filters.brand?.id || undefined,
            size: filters.size?.id || undefined,
            type: filters.type?.id || undefined,
        }
    }, [filters])

    const handleSubmitEdit: FormSubmitFn<PatternForm> = (
        values,
        formikHelpers
    ) => {
        updatePattern.mutate(
            {
                id: updatePatternModal.getState()!.id,
                data: {
                    name: values.name,
                    size: String(values.size?.id),
                    transparent: values.transparent,
                    plt: values.plt || undefined,
                    svg: values.svg || undefined,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.pattern.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PATTERNS_KEY,
                        'index',
                    ])
                    updatePatternModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const patternsQuery = usePatternQuery(apiFilters)
    const policy = usePolicy()

    if (!policy.isAdmin()) {
        return <div>Forbidden</div>
    }

    return (
        <PageLayout
            title={t('list.pattern.title')}
            breadCrumbs={[
                {
                    name: t('bread.device'),
                    url: '/device',
                },
                {
                    name: t('bread.pattern'),
                },
            ]}
            searchBarAction={(value) =>
                dispatchFilterAction({
                    type: 'setFilterQuery',
                    payload: value,
                })
            }
            actions={
                <>
                    <span className="ml-6">
                        <FilterButton
                            count={filterCount}
                            filtersExpanded={filtersExpanded}
                            onClick={() =>
                                setFiltersExpanded((prevState) => !prevState)
                            }
                            handleReset={() =>
                                dispatchFilterAction({
                                    type: 'resetFilters',
                                })
                            }
                        />
                    </span>
                </>
            }
        >
            <>
                {filtersExpanded && (
                    <PatternListFilters
                        filters={filters}
                        filterAction={dispatchFilterAction}
                    />
                )}
                <PatternList
                    patternsQuery={patternsQuery}
                    filters={filters}
                    filterCount={filterCount + filters.search_global.length}
                    handleEdit={(id, payload) => {
                        updatePatternModal.setState({ id, payload })
                        updatePatternModal.openModal()
                    }}
                    extendedData={true}
                    handlePltDownload={(pattern) =>
                        downloadPltFileHandler(pattern)
                    }
                    handleRemove={
                        policy.isAdmin()
                            ? (id) => {
                                  deletePatternForm.modal.setState(id)
                                  deletePatternForm.modal.openModal()
                              }
                            : undefined
                    }
                    filterAction={dispatchFilterAction}
                />
                <PatternModal
                    modal={updatePatternModal}
                    patternData={updatePatternModal.getState()?.payload}
                    onSubmit={handleSubmitEdit}
                />
                <ConfirmModal
                    modal={deletePatternForm.modal}
                    title={t('delete.pattern.title')}
                    description={t('delete.pattern.description')}
                    actionLabel={t('action.delete')}
                    onSubmit={deletePatternForm.form.submitForm}
                    isSubmitting={deletePatternForm.form.isSubmitting}
                />
            </>
        </PageLayout>
    )
}
