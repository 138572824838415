import React from 'react'
import { useTranslation } from 'react-i18next'

import { Label, Dropdown } from './ui'

import {
    DropdownItem,
    UserListFilterAction,
    UserListFilterState,
} from '../types'
import { useUserRoles } from '../hooks/useUserRoles'
import { useUserStatuses } from '../hooks/useUserStatuses'
import InfiniteDropdownWithSearchFilter from './ui/InfiniteDropdownWithSearchFilter'
import { getPlaces } from 'api'
import { placeMapFn } from 'helpers'

const UserListFilters: React.FC<{
    filters: UserListFilterState
    filterAction: React.Dispatch<UserListFilterAction>
}> = ({ filters, filterAction }) => {
    const { t } = useTranslation()

    const userStatuses = useUserStatuses()
    const userRoles = useUserRoles()

    const ALL_ITEM: DropdownItem = { id: '', name: t('dictionary.all') }

    return (
        <div className="mt-3 px-4 py-3 mb-4 bg-gray-800 rounded-lg">
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <Label>{t('filters.role')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(userRoles.list)}
                            value={
                                userRoles.list.find(
                                    (item) => item.id === filters.role?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterRole',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.country')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearchFilter
                            value={filters.country}
                            queryFilters={{ type: 'country' }}
                            queryFn={getPlaces}
                            name="country"
                            mapFn={placeMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterCountry',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.status')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(userStatuses)}
                            value={
                                userStatuses.find(
                                    (item) => item.id === filters.status?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterStatus',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserListFilters
