import { useNavigate } from 'react-router-dom'
import { TabPanel } from '@headlessui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { QUERY_CACHE_USERS_KEY } from '../constants/index'
import { useUpdateUser } from '../api'
import { UpdateUserRequest } from '../api/types'
import { useFlashMessage, useSupervisorsDropdown } from '../hooks'
import { usePayloadFromUserForm, useUserFormFromProfile } from '../hooks/form'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { usePolicy } from '../hooks/usePolicy'
import { useActivePlotterForm } from '../hooks/forms/usePlotterActiveForm'

import type { UserForm, FormSubmitFn } from '../types'

import PageLayout from '../components/PageLayout'
import User from '../components/User'
import UserTabView from '../components/UserTabView'
import UserFeatures from '../components/UserFeatures'
import { LANGUAGES } from '../constants/languages'
import SupervisorUsers from '../components/SupervisorUsers'

export default function ProfileView() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const navigate = useNavigate()
    const updateUserQuery = useUpdateUser({
        onSuccess: async () => {},
    })
    const activePlotterForm = useActivePlotterForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_USERS_KEY, userData?.id])
    })

    const userData = useCurrentUser().data?.data
    const policy = usePolicy()

    const userDropdownValues = useSupervisorsDropdown()

    const initialValues: UserForm = useUserFormFromProfile(userData)

    const payloadFromUserForm = usePayloadFromUserForm<UpdateUserRequest>()
    const handleSubmit: FormSubmitFn<UserForm> = (values, formikHelpers) => {
        updateUserQuery.mutate(
            {
                id: String(userData?.id!),
                data: payloadFromUserForm(values),
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.user.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    navigate('/user')
                    await Promise.all([
                        queryClient.invalidateQueries(['profile']),
                        queryClient.invalidateQueries([
                            QUERY_CACHE_USERS_KEY,
                            'index',
                        ]),
                    ])
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const title =
        userData?.first_name && userData?.last_name
            ? `${userData?.first_name} ${userData?.last_name}`
            : userData?.name

    return (
        <PageLayout
            title={title}
            breadCrumbs={[
                {
                    name: t('bread.user_list'),
                    url: '/user',
                },
                {
                    name: title || '',
                },
            ]}
        >
            <Formik<UserForm>
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, isSubmitting }) => (
                    <UserTabView haveUsers={policy.isSupervisor()}>
                        <TabPanel>
                            <User
                                values={values}
                                setFieldValue={setFieldValue}
                                isSubmitting={isSubmitting}
                                supervisors={userDropdownValues}
                                languages={LANGUAGES}
                                readonly={policy.isUser()}
                                isProfile={true}
                                plottersList={userData?.cutters || []}
                                activePlotterForm={activePlotterForm}
                            />
                        </TabPanel>
                        <TabPanel>
                            <UserFeatures
                                values={values}
                                setFieldValue={setFieldValue}
                                isSubmitting={isSubmitting}
                                readonly={true}
                            />
                        </TabPanel>
                        <TabPanel>
                            <SupervisorUsers id={String(userData?.id!)} />
                        </TabPanel>
                    </UserTabView>
                )}
            </Formik>
        </PageLayout>
    )
}
