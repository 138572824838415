import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import { getCxFromStyles } from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'
import { TextField, DropdownField } from './forms'

import type { FormSubmitFn, IModal, ProtectionFilmTypeForm } from '../types'

import styles from './Modal.module.scss'
import { ProtectionFilmTypeResponse } from '../api/types'
import {
    useKnifeTypesDropdown,
    usePressuresDropdown,
    useSpeedsDropdown,
    useTransparentsDropdown,
} from 'hooks'

export type ProtectionFilmTypeModalProps = {
    modal: IModal
    protectionFilmTypeData?: ProtectionFilmTypeResponse
    onSubmit: FormSubmitFn<ProtectionFilmTypeForm>
}

export default function ProtectionFilmTypeModal({
    modal,
    protectionFilmTypeData,
    onSubmit,
}: ProtectionFilmTypeModalProps) {
    const cx = getCxFromStyles(styles)
    const { t } = useTranslation()

    const speeds = useSpeedsDropdown()
    const pressures = usePressuresDropdown()
    const transparents = useTransparentsDropdown().dropdownItems
    const knifeTypes = useKnifeTypesDropdown()

    const initialValues: ProtectionFilmTypeForm = protectionFilmTypeData
        ? {
              name: protectionFilmTypeData.name,
              default_pressure:
                  pressures.dropdownItems.find(
                      (value) =>
                          value.id === protectionFilmTypeData.default_pressure
                  ) || pressures.default,
              default_speed:
                  speeds.dropdownItems.find(
                      (value) =>
                          value.id === protectionFilmTypeData.default_speed
                  ) || speeds.default,
              transparent:
                  transparents.find(
                      (value) =>
                          !!value.id === !!protectionFilmTypeData.transparent
                  ) || transparents[0],
              knife_type:
                  knifeTypes.find(
                      (value) => value.id === protectionFilmTypeData.knife_type
                  ) || knifeTypes[0],
          }
        : {
              name: '',
              default_pressure: pressures.default,
              default_speed: speeds.default,
              transparent: transparents[0],
              knife_type: knifeTypes[0],
          }

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg pb-6 leading-6 font-medium text-white text-center"
                >
                    {protectionFilmTypeData
                        ? t('form.protection_film_type.edit_title')
                        : t('form.protection_film_type.title')}
                </DialogTitle>
                <Formik<ProtectionFilmTypeForm>
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className="px-2 pb-2">
                            <div className="mr-auto max-w-3xl grid grid-cols-2 gap-4 pb-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t(
                                            'form.protection_film_type.field.name'
                                        )}
                                    </Label>
                                    <TextField name="name" />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t(
                                            'form.protection_film_type.field.speed'
                                        )}
                                    </Label>
                                    <DropdownField
                                        name="speed"
                                        value={values.default_speed}
                                        items={speeds.dropdownItems}
                                        onChange={(value) =>
                                            setFieldValue(
                                                'default_speed',
                                                value
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t(
                                            'form.protection_film_type.field.pressure'
                                        )}
                                    </Label>
                                    <DropdownField
                                        name="pressure"
                                        value={values.default_pressure}
                                        items={pressures.dropdownItems}
                                        onChange={(value) =>
                                            setFieldValue(
                                                'default_pressure',
                                                value
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t(
                                            'form.protection_film_type.field.transparent'
                                        )}
                                    </Label>
                                    <DropdownField
                                        name="transparent"
                                        value={values.transparent}
                                        items={transparents}
                                        onChange={(value) =>
                                            setFieldValue('transparent', value)
                                        }
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t(
                                            'form.protection_film_type.field.knife_type'
                                        )}
                                    </Label>
                                    <DropdownField
                                        name="knife_type"
                                        value={values.knife_type}
                                        items={knifeTypes}
                                        onChange={(value) =>
                                            setFieldValue('knife_type', value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    {t('form.save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
