import {
    BrandResponse,
    DeviceResponse,
    PatternResponse,
    PlaceResponse,
    PlotterResponse,
    UserResponse,
} from 'api/types'
import { DropdownItem } from 'types'

export const supervisorMapFn = (user: UserResponse): DropdownItem => ({
    name: `${user.first_name} ${user.last_name}`,
    description: user.name,
    id: user.id,
})

export const userMapFn = (user: UserResponse): DropdownItem => ({
    name: user.name ? user.name : `${user.first_name} ${user.last_name}`,
    description: user.email,
    id: user.id,
})

export const deviceMapFn = (device: DeviceResponse): DropdownItem => ({
    name: device.name,
    description: device.brand.name,
    id: device.id,
})

export const brandMapFn = (brand: BrandResponse): DropdownItem => ({
    name: brand.name,
    id: brand.id,
})

export const patternMapFn = (pattern: PatternResponse): DropdownItem => ({
    name: pattern.name,
    id: pattern.id,
})

export const plotterMapFn = (plotter: PlotterResponse): DropdownItem => ({
    name: plotter.name,
    id: plotter.id,
})

export const placeMapFn = (country: PlaceResponse): DropdownItem => ({
    name: country.name,
    id: country.id,
})
