import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Spinner } from './ui'
import TableFooter from './TableFooter'
import { Toggle, Button3dots } from './ui'
import Menu3dots from './Menu3dots'
import {
    TableHeader,
    TableContainer,
    Table,
    Tbody,
    Tempty,
    Tr,
} from './ui/List'

import {
    PresentationChartBarIcon,
    LockClosedIcon,
    PencilAltIcon,
} from '@heroicons/react/solid'
import { TrashIcon } from '@heroicons/react/outline'

import type { UseQueryResult } from 'react-query'
import type { UserListFilterState, UserListFilterAction } from '../types'
import type { ResponseList, UserListResponse } from '../api/types'
import { useUserRoles } from '../hooks'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { usePolicy } from '../hooks/usePolicy'
import { useRemindPasswordForm } from '../hooks/forms/useRemindPasswordForm'
import SortButton from './ui/SortButton'

type UserListProps = {
    usersQuery: UseQueryResult<ResponseList<UserListResponse>>
    filters: UserListFilterState
    filterCount: number
    filterAction: React.Dispatch<UserListFilterAction>
    handleToggle: (id: number, state: boolean) => void
    handleRemove: (id: number) => void
}

type TableProps = {
    data: UserListResponse
    handleToggle: (id: number, state: boolean) => void
    handleRemove: (id: number) => void
}

const UserList: React.FC<UserListProps> = ({
    usersQuery,
    filters,
    handleToggle,
    filterCount,
    filterAction,
    handleRemove,
}) => {
    const { t } = useTranslation()

    return (
        <TableContainer>
            <Table>
                <TableHeader>
                    <th
                        scope="col"
                        className="w-[95px] px-6 py-4 text-left tracking-wider"
                    >
                        <SortButton
                            disabled={usersQuery.isLoading}
                            filters={filters}
                            action={filterAction}
                            name="id"
                        >
                            <div className="text-left tracking-wider uppercase">
                                {t('list.user.id')}
                            </div>
                        </SortButton>
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        <SortButton
                            disabled={usersQuery.isLoading}
                            filters={filters}
                            action={filterAction}
                            name="name"
                        >
                            <div className="text-left tracking-wider uppercase">
                                {t('list.user.name')}
                            </div>
                        </SortButton>
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.user.email')}
                    </th>
                    <th
                        scope="col"
                        className="w-auto min-w-[80px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        <SortButton
                            disabled={usersQuery.isLoading}
                            filters={filters}
                            action={filterAction}
                            name="country"
                        >
                            <div className="text-left tracking-wider uppercase">
                                {t('list.user.country')}
                            </div>
                        </SortButton>
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[80px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.user.role')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[80px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.user.status')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[65px] px-6 py-4 text-left tracking-wider"
                    ></th>
                </TableHeader>
                {usersQuery.isSuccess && usersQuery.isFetched && (
                    <>
                        {usersQuery.data.meta.total > 0 && (
                            <TableBody
                                data={usersQuery.data.data}
                                handleToggle={handleToggle}
                                handleRemove={handleRemove}
                            />
                        )}
                    </>
                )}
                {usersQuery.data?.meta.total === 0 && (
                    <Tbody>
                        <Tr key={-1} index={0}>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4 relative h-12">
                                {filterCount === 0 && (
                                    <Tempty>{t('list.user.empty_list')}</Tempty>
                                )}
                                {filterCount > 0 && (
                                    <Tempty>
                                        {t('list.user.empty_search')}
                                    </Tempty>
                                )}
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                        </Tr>
                    </Tbody>
                )}
            </Table>
            {usersQuery.isLoading && (
                <div className="divide-y divide-gray-700 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-800': index % 2,
                                    'bg-background': !(index % 2),
                                })}
                            >
                                {index === 4 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            <TableFooter
                meta={usersQuery.data?.meta}
                filterAction={filterAction}
            />
        </TableContainer>
    )
}

const CountryValue: React.FC<{ value: string | null }> = ({ value }) => {
    const { t } = useTranslation()

    const translated = useMemo(() => (value ? value : '-'), [value, t])

    return <td className="px-6 py-4">{translated}</td>
}

const TableBody: React.FC<TableProps> = ({
    data,
    handleToggle,
    handleRemove,
}) => {
    const { t } = useTranslation()
    const userRoles = useUserRoles()

    const currentuser = useCurrentUser()
    const remindPasswordForm = useRemindPasswordForm()

    const policy = usePolicy()
    const { user, supervisor, admin } = useUserRoles()

    return (
        <Tbody>
            {data.map((item, index) => (
                <Tr key={item.id} index={index}>
                    <td className="px-6 py-4">
                        <span>{item.id}</span>
                    </td>
                    <td className="px-6 py-4">{item.name}</td>
                    <td className="px-6 py-4">
                        <span>{item.email}</span>
                    </td>
                    <CountryValue value={item.country} />
                    <td className="px-6 py-4">
                        <span>
                            {item.role?.id
                                ? userRoles.list.find(
                                      (role) => role.id === item.role?.id
                                  )?.name
                                : '-'}
                        </span>
                    </td>
                    <td className="px-6 py-4">
                        <Toggle
                            checked={item.active}
                            disabled={item.id === currentuser.data?.data.id}
                            handleChange={(value) =>
                                handleToggle(item.id, value)
                            }
                        />
                    </td>
                    <td className="px-6 py-4 text-right">
                        <Menu3dots>
                            {({ open }) => (
                                <>
                                    <Menu3dots.Button
                                        as={
                                            remindPasswordForm.form.isSubmitting
                                                ? React.Fragment
                                                : 'span'
                                        }
                                    >
                                        <Button3dots
                                            open={open}
                                            loading={
                                                remindPasswordForm.form
                                                    .isSubmitting
                                            }
                                        />
                                    </Menu3dots.Button>
                                    <Menu3dots.Items>
                                        <>
                                            <div className="divide-y divide-gray-200 py-1">
                                                <Menu3dots.Item
                                                    as="link"
                                                    to={`/user/${item.id}/edit`}
                                                    state={{
                                                        backUrl: '/user',
                                                        state: { id: item.id },
                                                    }}
                                                >
                                                    <PencilAltIcon className="w-5 h-5 mr-3" />
                                                    {t('list.user.edit')}
                                                </Menu3dots.Item>
                                            </div>
                                            <div className="divide-y divide-gray-200 py-1">
                                                <Menu3dots.Item
                                                    onClick={() =>
                                                        remindPasswordForm.sendForm(
                                                            item.email
                                                        )
                                                    }
                                                >
                                                    <LockClosedIcon className="w-5 h-5 mr-3" />
                                                    {t(
                                                        'list.user.send_password'
                                                    )}
                                                </Menu3dots.Item>
                                            </div>
                                            <div className="divide-y divide-gray-200 py-1">
                                                <Menu3dots.Item
                                                    as="link"
                                                    disabled={
                                                        item.role?.id ===
                                                        admin.id
                                                    }
                                                    to={
                                                        item.role?.id ===
                                                        supervisor.id
                                                            ? `/stats/supervisor/${item.id}`
                                                            : item.role?.id ===
                                                                user.id
                                                              ? `/stats/user/${item.id}`
                                                              : ''
                                                    }
                                                    state={{
                                                        backUrl: '/user',
                                                        state: { id: item.id },
                                                    }}
                                                >
                                                    <PresentationChartBarIcon className="w-5 h-5 mr-3" />
                                                    {t('list.user.stats')}
                                                </Menu3dots.Item>
                                            </div>
                                            {policy.isAdmin() && (
                                                <div className="divide-y border-t-[1px] border-gray-700 divide-gray-200 py-1">
                                                    <Menu3dots.Item
                                                        disabled={
                                                            item.id ===
                                                            currentuser.data
                                                                ?.data.id
                                                        }
                                                        onClick={() =>
                                                            handleRemove(
                                                                item.id
                                                            )
                                                        }
                                                    >
                                                        <TrashIcon className="w-5 h-5 mr-3" />
                                                        {t('list.user.remove')}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}
                                            {policy.isSupervisor() && (
                                                <div className="divide-y border-t-[1px] border-gray-700 divide-gray-200 py-1">
                                                    <Menu3dots.Item
                                                        disabled={
                                                            !item.active ||
                                                            item.id ===
                                                                currentuser.data
                                                                    ?.data.id
                                                        }
                                                        onClick={() =>
                                                            handleToggle(
                                                                item.id,
                                                                false
                                                            )
                                                        }
                                                    >
                                                        <TrashIcon className="w-5 h-5 mr-3" />
                                                        {t(
                                                            'list.user.deactivate'
                                                        )}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}
                                        </>
                                    </Menu3dots.Items>
                                </>
                            )}
                        </Menu3dots>
                    </td>
                </Tr>
            ))}
        </Tbody>
    )
}

export default UserList
