import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { useDeleteProtectionFilmType } from '../../api'
import { useFlashMessage, useModal } from '..'

export function useDeleteProtectionFilmTypeForm(onSuccess?: () => void) {
    const { t } = useTranslation()

    const flashMessage = useFlashMessage()

    const deleteProtectionFilmType = useDeleteProtectionFilmType({
        onSuccess: async () => {},
    })
    const confirmModal = useModal<number>()

    const form = useFormik({
        initialValues: {
            active: false,
        },
        onSubmit: (values, formikHelpers) => {
            deleteProtectionFilmType.mutate(
                {
                    id: String(confirmModal.getState()!),
                },
                {
                    onSuccess: async () => {
                        confirmModal.closeModal()
                        flashMessage({
                            type: 'success',
                            content: t('info.protection_film_type.deleted'),
                        })
                        formikHelpers.setSubmitting(false)
                        onSuccess && onSuccess()
                    },
                    onError: (error) => {
                        formikHelpers.setErrors(error.errors)
                        formikHelpers.setSubmitting(false)
                    },
                }
            )
        },
    })

    return {
        modal: confirmModal,
        form: form,
    }
}
