import { useTranslation } from 'react-i18next'
import { useRolesQuery } from '../api/endpoints/roles'
import { useMemo } from 'react'

const DEFAULT_USER_ROLE_ID = 3
const DEFAULT_SUPERVISOR_ROLE_ID = 2
const DEFAULT_ADMIN_ROLE_ID = 1

const ADMIN_VALUE = 'admin'
const SUPERIOR_VALUE = 'superior'
const TRADER_VALUE = 'trader'

export const useUserRoles = () => {
    const { t } = useTranslation()

    const rolesQuery = useRolesQuery()

    const user = useMemo(
        () => ({
            id:
                rolesQuery.data?.data.find(({ name }) => name === TRADER_VALUE)
                    ?.id || DEFAULT_USER_ROLE_ID,
            name: t('dictionary.user'),
            short_name: t('dictionary.user_short'),
            description: t('dictionary.user_description'),
        }),
        [t, rolesQuery.data]
    )

    const supervisor = useMemo(
        () => ({
            id:
                rolesQuery.data?.data.find(
                    ({ name }) => name === SUPERIOR_VALUE
                )?.id || DEFAULT_SUPERVISOR_ROLE_ID,
            name: t('dictionary.supervisor'),
            short_name: t('dictionary.supervisor_short'),
            description: t('dictionary.supervisor_description'),
        }),
        [t, rolesQuery.data]
    )

    const admin = useMemo(
        () => ({
            id:
                rolesQuery.data?.data.find(({ name }) => name === ADMIN_VALUE)
                    ?.id || DEFAULT_ADMIN_ROLE_ID,
            name: t('dictionary.admin'),
            short_name: t('dictionary.admin_short'),
            description: t('dictionary.admin_description'),
        }),
        [t, rolesQuery.data]
    )

    return useMemo(
        () => ({
            list: [user, supervisor, admin],
            user,
            supervisor,
            admin,
        }),
        [user, supervisor, admin]
    )
}
