import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'

import Sidebar from './Sidebar'

const MainLayout: React.FC = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false)

    return (
        <div className="relative min-h-screen flex max-w-full print:min-h-0">
            <Sidebar
                isSidebarOpen={isSidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            <div
                id="main-layout-right-side"
                className={
                    'flex flex-col flex-1 transition-all lg:ml-64 pt-12 lg:pt-0 main-layout-right-side print:m-0 print:max-w-full'
                }
            >
                <main className="flex flex-col flex-1 max-w-full">
                    <Outlet />
                </main>
            </div>
        </div>
    )
}

export default MainLayout
