import React, { useState, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import {
    DEFAULT_LIST_PER_PAGE,
    QUERY_CACHE_DEVICES_KEY,
} from '../constants/index'
import {
    useBrandByIdQuery,
    useCreateDevice,
    useDeviceQuery,
    useUpdateDevice,
} from '../api'
import { listReducer } from '../helpers'
import { Button, FilterButton, Loader } from '../components/ui'
import PageLayout from '../components/PageLayout'
import DeviceList from '../components/DeviceList'

import type {
    DeviceListFilterState,
    DeviceListFilterAction,
    ListFilterAction,
    DeviceForm,
    FormSubmitFn,
} from '../types'
import ConfirmModal from '../components/ConfirmModal'
import { useDeleteDeviceForm } from '../hooks/forms/useDeleteDeviceForm'
import { useQueryClient } from 'react-query'
import DeviceModal from '../components/DeviceModal'
import { useFlashMessage, useModal } from '../hooks'
import { BrandResponse, DeviceResponse } from '../api/types'
import { usePolicy } from '../hooks/usePolicy'
import moment from 'moment'
import DeviceListFilters from 'components/DeviceListFilters'
import { useParams } from 'react-router-dom'

const filterReducer = (
    state: DeviceListFilterState,
    action: DeviceListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterBrand') {
        return {
            ...state,
            brand: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterType') {
        return {
            ...state,
            type: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterPatterns') {
        return {
            ...state,
            hasPatterns: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            hasPatterns: undefined,
            type: undefined,
            brand: undefined,
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as DeviceListFilterState
}

export default function DeviceListView() {
    const { id } = useParams()
    const policy = usePolicy()
    const brandQuery = useBrandByIdQuery(id!, {
        enabled: !!id,
    })

    if (!policy.isAdmin()) {
        return <div>Forbidden</div>
    }

    if (!id) {
        return <DeviceListViewInner />
    }

    if (brandQuery.isLoading) {
        return <Loader />
    }

    if (brandQuery.isError) {
        return <div className="text-red-600">{brandQuery.error.message}</div>
    }

    if (!brandQuery.isSuccess) {
        return null
    }

    return <DeviceListViewInner brand={brandQuery.data?.data} />
}

function DeviceListViewInner({ brand }: { brand?: BrandResponse }) {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()

    const createDeviceModal = useModal()
    const updateDeviceModal = useModal<{
        id: number
        payload: DeviceResponse
    }>()

    const deleteDeviceForm = useDeleteDeviceForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_DEVICES_KEY, 'index'])
    })

    const createDevice = useCreateDevice()
    const updateDevice = useUpdateDevice()
    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        brand: brand,
        type: undefined,
        hasPatterns: undefined,
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)
    const filterCount = useMemo(
        () =>
            (filters.brand ? 1 : 0) +
            (filters.type ? 1 : 0) +
            (filters.hasPatterns ? 1 : 0),
        [filters]
    )

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            search_global: filters.search_global || undefined,
            brand_id: filters.brand ? filters.brand.id : undefined,
            type: filters.type ? filters.type.id : undefined,
            has_patterns: filters.hasPatterns
                ? filters.hasPatterns.id === 'has'
                    ? true
                    : false
                : undefined,
        }
    }, [filters])

    const handleSubmit: FormSubmitFn<DeviceForm> = (values, formikHelpers) => {
        createDevice.mutate(
            {
                data: {
                    name: values.name,
                    brand: values.brand?.id
                        ? parseInt(String(values.brand?.id))
                        : undefined,
                    type: String(values.type?.id),
                    release_date: moment(values.release_date).format(
                        'YYYY-MM-DD'
                    ),
                    tags: values.tags,
                    image: values.image,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.device.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_DEVICES_KEY,
                        'index',
                    ])
                    createDeviceModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitEdit: FormSubmitFn<DeviceForm> = (
        values,
        formikHelpers
    ) => {
        updateDevice.mutate(
            {
                id: updateDeviceModal.getState()!.id,
                data: {
                    name: values.name,
                    brand: values.brand?.id
                        ? parseInt(String(values.brand?.id))
                        : undefined,
                    type: String(values.type?.id),
                    release_date: moment(values.release_date).format(
                        'YYYY-MM-DD'
                    ),
                    tags: values.tags,
                    image: values.image || undefined,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.device.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_DEVICES_KEY,
                        'index',
                    ])
                    updateDeviceModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const devicesQuery = useDeviceQuery(apiFilters)

    return (
        <PageLayout
            title={brand?.name || t('list.device.title')}
            searchBarAction={(value) =>
                dispatchFilterAction({
                    type: 'setFilterQuery',
                    payload: value,
                })
            }
            actions={
                <>
                    <span className="ml-2">
                        <Button onClick={createDeviceModal.openModal}>
                            {t('action.device.create')}
                        </Button>
                    </span>
                    <span className="ml-6">
                        <FilterButton
                            count={filterCount}
                            filtersExpanded={filtersExpanded}
                            onClick={() =>
                                setFiltersExpanded((prevState) => !prevState)
                            }
                            handleReset={() =>
                                dispatchFilterAction({
                                    type: 'resetFilters',
                                })
                            }
                        />
                    </span>
                </>
            }
        >
            <>
                {filtersExpanded && (
                    <DeviceListFilters
                        filters={filters}
                        filterAction={dispatchFilterAction}
                    />
                )}
                <DeviceList
                    devicesQuery={devicesQuery}
                    filters={filters}
                    filterCount={filterCount + filters.search_global.length}
                    handleEdit={(id, payload) => {
                        updateDeviceModal.setState({ id, payload })
                        updateDeviceModal.openModal()
                    }}
                    handleRemove={(id) => {
                        deleteDeviceForm.modal.setState(id)
                        deleteDeviceForm.modal.openModal()
                    }}
                    filterAction={dispatchFilterAction}
                />
                <DeviceModal
                    modal={createDeviceModal}
                    onSubmit={handleSubmit}
                />
                <DeviceModal
                    modal={updateDeviceModal}
                    deviceData={updateDeviceModal.getState()?.payload}
                    onSubmit={handleSubmitEdit}
                />
                <ConfirmModal
                    modal={deleteDeviceForm.modal}
                    title={t('delete.device.title')}
                    description={t('delete.device.description')}
                    actionLabel={t('action.delete')}
                    onSubmit={deleteDeviceForm.form.submitForm}
                    isSubmitting={deleteDeviceForm.form.isSubmitting}
                />
            </>
        </PageLayout>
    )
}
