import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Spinner } from './ui'
import TableFooter from './TableFooter'
import { Button3dots } from './ui'
import Menu3dots from './Menu3dots'
import {
    TableHeader,
    TableContainer,
    Table,
    Tbody,
    Tempty,
    Tr,
} from './ui/List'

import { PencilAltIcon } from '@heroicons/react/solid'
import { TrashIcon } from '@heroicons/react/outline'

import type { UseQueryResult } from 'react-query'
import type {
    ProtectionFilmTypeListFilterState,
    ProtectionFilmTypeListFilterAction,
} from '../types'
import type {
    ResponseList,
    ProtectionFilmTypeListResponse,
    ProtectionFilmTypeResponse,
} from '../api/types'
import SortButton from './ui/SortButton'

type ProtectionFilmTypeListProps = {
    protectionFilmTypesQuery: UseQueryResult<
        ResponseList<ProtectionFilmTypeListResponse>
    >
    filters: ProtectionFilmTypeListFilterState
    filterCount: number
    filterAction: React.Dispatch<ProtectionFilmTypeListFilterAction>
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: ProtectionFilmTypeResponse) => void
}

type TableProps = {
    data: ProtectionFilmTypeListResponse
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: ProtectionFilmTypeResponse) => void
}

const ProtectionFilmTypeList: React.FC<ProtectionFilmTypeListProps> = ({
    protectionFilmTypesQuery,
    filters,
    filterCount,
    filterAction,
    handleRemove,
    handleEdit,
}) => {
    const { t } = useTranslation()

    return (
        <TableContainer>
            <Table>
                <TableHeader>
                    <th scope="col" className="w-[95px] px-6 py-4">
                        <SortButton
                            disabled={protectionFilmTypesQuery.isLoading}
                            filters={filters}
                            action={filterAction}
                            name="id"
                        >
                            <div className="text-left tracking-wider uppercase">
                                {t('list.protection_film_type.id')}
                            </div>
                        </SortButton>
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        <SortButton
                            disabled={protectionFilmTypesQuery.isLoading}
                            filters={filters}
                            action={filterAction}
                            name="name"
                        >
                            <div className="text-left tracking-wider uppercase">
                                {t('list.protection_film_type.name')}
                            </div>
                        </SortButton>
                    </th>
                    <th
                        scope="col"
                        className="w-[95px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.protection_film_type.speed')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[160px] px-6 py-4 text-left tracking-wider"
                    >
                        <div className="text-left tracking-wider uppercase">
                            {t('list.protection_film_type.pressure')}
                        </div>
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[100px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.protection_film_type.transparent')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[100px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.protection_film_type.knife_type')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[65px] px-6 py-4 text-left tracking-wider"
                    ></th>
                </TableHeader>
                {protectionFilmTypesQuery.isSuccess &&
                    protectionFilmTypesQuery.isFetched && (
                        <>
                            {protectionFilmTypesQuery.data.meta.total > 0 && (
                                <TableBody
                                    data={protectionFilmTypesQuery.data.data}
                                    handleRemove={handleRemove}
                                    handleEdit={handleEdit}
                                />
                            )}
                        </>
                    )}
                {protectionFilmTypesQuery.data?.meta.total === 0 && (
                    <Tbody>
                        <Tr key={-1} index={0}>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4 relative h-12">
                                {filterCount === 0 && (
                                    <Tempty>
                                        {t(
                                            'list.protection_film_type.empty_list'
                                        )}
                                    </Tempty>
                                )}
                                {filterCount > 0 && (
                                    <Tempty>
                                        {t(
                                            'list.protection_film_type.empty_search'
                                        )}
                                    </Tempty>
                                )}
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                        </Tr>
                    </Tbody>
                )}
            </Table>
            {protectionFilmTypesQuery.isLoading && (
                <div className="divide-y divide-gray-700 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-800': index % 2,
                                    'bg-background': !(index % 2),
                                })}
                            >
                                {index === 4 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            {filterAction && (
                <TableFooter
                    meta={protectionFilmTypesQuery.data?.meta}
                    filterAction={filterAction}
                />
            )}
        </TableContainer>
    )
}

const TableBody: React.FC<TableProps> = ({
    data,
    handleRemove,
    handleEdit,
}) => {
    const { t } = useTranslation()

    const isMutating = false
    return (
        <Tbody>
            {data.map((item, index) => (
                <Tr key={item.id} index={index}>
                    <td className="px-6 py-4">
                        <span>{item.id}</span>
                    </td>
                    <td className="px-6 py-4">{item.name}</td>
                    <td className="px-6 py-4">{item.default_speed}</td>
                    <td className="px-6 py-4">{item.default_pressure}</td>
                    <td className="px-6 py-4">
                        {item.transparent
                            ? t('protection_films_transparent.transparent')
                            : t('protection_films_transparent.not_transparent')}
                    </td>
                    <td className="px-6 py-4">
                        {t(`knife_type.${item.knife_type}`)}
                    </td>
                    <td className="px-6 py-4 text-right">
                        <Menu3dots>
                            {({ open }) => (
                                <>
                                    <Menu3dots.Button
                                        as={
                                            isMutating ? React.Fragment : 'span'
                                        }
                                    >
                                        <Button3dots
                                            open={open}
                                            loading={isMutating}
                                        />
                                    </Menu3dots.Button>
                                    <Menu3dots.Items>
                                        <>
                                            {handleEdit && (
                                                <div className="divide-y border-t-[1px] border-gray-700 py-1">
                                                    <Menu3dots.Item
                                                        onClick={() =>
                                                            handleEdit
                                                                ? handleEdit(
                                                                      item.id,
                                                                      item
                                                                  )
                                                                : {}
                                                        }
                                                    >
                                                        <PencilAltIcon className="w-5 h-5 mr-3" />
                                                        {t(
                                                            'list.protection_film_type.edit'
                                                        )}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}

                                            {handleRemove && (
                                                <div className="divide-y border-t-[1px] border-gray-700 divide-gray-200 py-1">
                                                    <Menu3dots.Item
                                                        onClick={() =>
                                                            handleRemove
                                                                ? handleRemove(
                                                                      item.id
                                                                  )
                                                                : {}
                                                        }
                                                    >
                                                        <TrashIcon className="w-5 h-5 mr-3" />
                                                        {t(
                                                            'list.protection_film_type.delete'
                                                        )}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}
                                        </>
                                    </Menu3dots.Items>
                                </>
                            )}
                        </Menu3dots>
                    </td>
                </Tr>
            ))}
        </Tbody>
    )
}

export default ProtectionFilmTypeList
