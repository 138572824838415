import React, { useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import {
    DEFAULT_LIST_PER_PAGE,
    QUERY_CACHE_PROTECTION_FILM_TYPES_KEY,
} from '../constants/index'
import {
    useCreateProtectionFilmType,
    useProtectionFilmTypeQuery,
    useUpdateProtectionFilmType,
} from '../api'
import { listReducer } from '../helpers'
import { Button } from '../components/ui'
import PageLayout from '../components/PageLayout'
import ProtectionFilmTypeList from '../components/ProtectionFilmTypeList'

import type {
    ProtectionFilmTypeListFilterState,
    ProtectionFilmTypeListFilterAction,
    ListFilterAction,
    ProtectionFilmTypeForm,
    FormSubmitFn,
} from '../types'
import ConfirmModal from '../components/ConfirmModal'
import { useDeleteProtectionFilmTypeForm } from '../hooks/forms/useDeleteProtectionFilmTypeForm'
import { useQueryClient } from 'react-query'
import ProtectionFilmTypeModal from '../components/ProtectionFilmTypeModal'
import { useFlashMessage, useModal } from '../hooks'
import { ProtectionFilmTypeResponse } from '../api/types'
import { usePolicy } from '../hooks/usePolicy'

const filterReducer = (
    state: ProtectionFilmTypeListFilterState,
    action: ProtectionFilmTypeListFilterAction
) => {
    return listReducer(
        state,
        action as ListFilterAction
    ) as ProtectionFilmTypeListFilterState
}

export default function ProtectionFilmTypeListView() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const createProtectionFilmTypeModal = useModal()
    const updateProtectionFilmTypeModal = useModal<{
        id: number
        payload: ProtectionFilmTypeResponse
    }>()

    const deleteProtectionFilmTypeForm = useDeleteProtectionFilmTypeForm(() => {
        queryClient.invalidateQueries([
            QUERY_CACHE_PROTECTION_FILM_TYPES_KEY,
            'index',
        ])
    })

    const createProtectionFilmType = useCreateProtectionFilmType()
    const updateProtectionFilmType = useUpdateProtectionFilmType()
    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        // search_global: '',
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    const filterCount = 0

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            // search_global: filters.search_global || undefined,
        }
    }, [filters])

    const handleSubmit: FormSubmitFn<ProtectionFilmTypeForm> = (
        values,
        formikHelpers
    ) => {
        createProtectionFilmType.mutate(
            {
                data: {
                    name: values.name,
                    default_pressure:
                        typeof values.default_pressure.id === 'number'
                            ? values.default_pressure.id
                            : parseInt(values.default_pressure.id),
                    default_speed:
                        typeof values.default_speed.id === 'number'
                            ? values.default_speed.id
                            : parseInt(values.default_speed.id),
                    transparent: !!values.transparent.id,
                    knife_type: values.knife_type.id.toString(),
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.protection_film_type.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PROTECTION_FILM_TYPES_KEY,
                        'index',
                    ])
                    createProtectionFilmTypeModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitEdit: FormSubmitFn<ProtectionFilmTypeForm> = (
        values,
        formikHelpers
    ) => {
        updateProtectionFilmType.mutate(
            {
                id: updateProtectionFilmTypeModal.getState()!.id,
                data: {
                    name: values.name,
                    default_pressure:
                        typeof values.default_pressure.id === 'number'
                            ? values.default_pressure.id
                            : parseInt(values.default_pressure.id),
                    default_speed:
                        typeof values.default_speed.id === 'number'
                            ? values.default_speed.id
                            : parseInt(values.default_speed.id),
                    transparent: !!values.transparent.id,
                    knife_type: values.knife_type.id.toString(),
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.protection_film_type.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PROTECTION_FILM_TYPES_KEY,
                        'index',
                    ])
                    updateProtectionFilmTypeModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const protectionFilmTypesQuery = useProtectionFilmTypeQuery(apiFilters)
    const policy = usePolicy()

    if (!policy.isAdmin()) {
        return <div>Forbidden</div>
    }

    return (
        <PageLayout
            title={t('list.protection_film_type.title')}
            // searchBarAction={(value) =>
            //     dispatchFilterAction({
            //         type: 'setFilterQuery',
            //         payload: value,
            //     })
            // }
            actions={
                <>
                    <span className="ml-2">
                        <Button
                            onClick={createProtectionFilmTypeModal.openModal}
                        >
                            {t('action.protection_film_type.create')}
                        </Button>
                    </span>
                </>
            }
        >
            <>
                <ProtectionFilmTypeList
                    protectionFilmTypesQuery={protectionFilmTypesQuery}
                    filters={filters}
                    filterCount={filterCount} //  + filters.search_global.length
                    handleEdit={(id, payload) => {
                        updateProtectionFilmTypeModal.setState({ id, payload })
                        updateProtectionFilmTypeModal.openModal()
                    }}
                    handleRemove={(id) => {
                        deleteProtectionFilmTypeForm.modal.setState(id)
                        deleteProtectionFilmTypeForm.modal.openModal()
                    }}
                    filterAction={dispatchFilterAction}
                />
                <ProtectionFilmTypeModal
                    modal={createProtectionFilmTypeModal}
                    onSubmit={handleSubmit}
                />
                <ProtectionFilmTypeModal
                    modal={updateProtectionFilmTypeModal}
                    protectionFilmTypeData={
                        updateProtectionFilmTypeModal.getState()?.payload
                    }
                    onSubmit={handleSubmitEdit}
                />
                <ConfirmModal
                    modal={deleteProtectionFilmTypeForm.modal}
                    title={t('delete.protection_film_type.title')}
                    description={t('delete.protection_film_type.description')}
                    actionLabel={t('action.delete')}
                    onSubmit={deleteProtectionFilmTypeForm.form.submitForm}
                    isSubmitting={
                        deleteProtectionFilmTypeForm.form.isSubmitting
                    }
                />
            </>
        </PageLayout>
    )
}
