export function MultipleColourGradients(
    colours: string[],
    minNum = 0,
    maxNum = 100
) {
    if (colours.length < 2) {
        throw new Error('Rainbow must have two or more colours.')
    }
    const increment = (maxNum - minNum) / (colours.length - 1)

    const gradients = colours
        .slice(0, colours.length - 1)
        .map((_, index) =>
            ColourGradient(
                colours[0],
                colours[index + 1],
                minNum + increment * index,
                minNum + increment * (index + 1)
            )
        )

    const colourAt = function (number: number) {
        if (gradients.length === 1) {
            return gradients[0].colourAt(number)
        } else {
            const segment = (maxNum - minNum) / gradients.length
            const index = Math.min(
                Math.floor((Math.max(number, minNum) - minNum) / segment),
                gradients.length - 1
            )
            return gradients[index].colourAt(number)
        }
    }

    return { colourAt }
}

export function ColourGradient(
    startColour: string,
    endColour: string,
    minNum = 100,
    maxNum = 100
) {
    const colourAt = function (number: number) {
        return (
            '#' +
            calcHex(
                number,
                startColour.substring(0, 2),
                endColour.substring(0, 2)
            ) +
            calcHex(
                number,
                startColour.substring(2, 4),
                endColour.substring(2, 4)
            ) +
            calcHex(
                number,
                startColour.substring(4, 6),
                endColour.substring(4, 6)
            )
        )
    }

    function calcHex(
        number: number,
        channelStart_Base16: string,
        channelEnd_Base16: string
    ) {
        const num = number < minNum ? minNum : number > maxNum ? maxNum : number
        const numRange = maxNum - minNum
        const cStart_Base10 = parseInt(channelStart_Base16, 16)
        const cEnd_Base10 = parseInt(channelEnd_Base16, 16)
        const cPerUnit = (cEnd_Base10 - cStart_Base10) / numRange
        const c_Base10 = Math.round(cPerUnit * (num - minNum) + cStart_Base10)
        return formatHex(c_Base10.toString(16))
    }

    return { colourAt }
}

function formatHex(hex: string) {
    if (hex.length === 1) {
        return '0' + hex
    } else {
        return hex
    }
}
