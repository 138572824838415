import React, { useRef } from 'react'
import { DayPicker } from 'react-day-picker'

import { getCxFromStyles } from 'helpers'
import { useEventListener } from 'hooks'
import {
    useGetDatePickerConfig,
    CommonDatePickerProps,
    DatePickerInput,
    useDatePickerPopper,
} from './DatePickerBase'

import 'react-day-picker/dist/style.css'
import styles from './DatePicker.module.scss'

export interface DatePickerProps extends CommonDatePickerProps<Date> {
    placeholder?: string
    tabIndex?: number
    stylesPopper?: React.CSSProperties
    onDatePickerOpen?: () => void
    renderSelector?: ({
        ref,
        isOpen,
        open,
        close,
    }: {
        ref: React.RefObject<HTMLDivElement>
        isOpen: boolean
        open: () => void
        close: () => void
    }) => JSX.Element
}

const DatePicker: React.FC<DatePickerProps> = ({
    placeholder = 'Data',
    tabIndex,
    captionLayout,
    startMonth,
    endMonth,
    blockedDays = [],
    disabledBefore,
    disabledAfter,
    value,
    disabled,
    inputReadOnly,
    stylesPopper,
    hasError,
    onChange,
    onMonthChange,
    onDatePickerOpen,
    renderSelector,
}) => {
    const cx = getCxFromStyles(styles)
    const ref = useRef<HTMLDivElement | null>(null)

    const {
        popperRef,
        setPopperElement,
        popperState,
        popper,
        handleClosePopper,
        handleOpenPopper,
    } = useDatePickerPopper()

    const handleOnClick = () => {
        !popperState.isOpen && handleOpenPopper()
        !!onDatePickerOpen && onDatePickerOpen()
    }

    const datePickerConfig = useGetDatePickerConfig(blockedDays)

    const handleClickOutside = (e: Event) => {
        if (
            ref.current &&
            e.target instanceof Node &&
            !ref.current.contains(e.target)
        ) {
            handleClosePopper()
        }
    }

    useEventListener('mousedown', handleClickOutside)

    const onInputSelect = (date?: Date, closeDayPicker?: boolean) => {
        closeDayPicker = closeDayPicker ?? true
        onChange(date)
        closeDayPicker && handleClosePopper()
    }

    const onDayPickerSelect = (date?: Date) => {
        onChange(date)
        handleClosePopper()
    }

    return (
        <>
            {renderSelector ? (
                renderSelector({
                    ref: popperRef,
                    isOpen: popperState.isOpen,
                    open: handleOnClick,
                    close: handleClosePopper,
                })
            ) : (
                <DatePickerInput
                    ref={popperRef}
                    tabIndex={tabIndex}
                    value={value}
                    onClick={handleOnClick}
                    placeholder={placeholder}
                    disabled={disabled}
                    readOnly={inputReadOnly}
                    hasError={hasError}
                    onSelect={onInputSelect}
                    active={popperState.isOpen && !disabled}
                />
            )}
            {!disabled && popperState.isOpen && (
                <div ref={ref}>
                    <div
                        tabIndex={-1}
                        className="z-20"
                        style={{ ...popper.styles.popper, ...stylesPopper }}
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                    >
                        <div
                            className={cx('root', {
                                isSingleDate: true,
                            })}
                        >
                            <DayPicker
                                {...datePickerConfig}
                                mode="single"
                                captionLayout={captionLayout}
                                startMonth={startMonth}
                                endMonth={endMonth}
                                onMonthChange={onMonthChange}
                                defaultMonth={value}
                                selected={value}
                                onSelect={onDayPickerSelect}
                                disabled={
                                    disabledBefore && disabledAfter
                                        ? [
                                              { before: disabledBefore },
                                              { after: disabledAfter },
                                          ]
                                        : disabledBefore
                                          ? { before: disabledBefore }
                                          : disabledAfter
                                            ? { after: disabledAfter }
                                            : undefined
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default DatePicker
