import React from 'react'
import { useTranslation } from 'react-i18next'

import { QUERY_CACHE_PROTECTION_FILMS_KEY } from '../constants/index'
import {
    useDownloadQRCodes,
    useGenerateQrCodes,
    useGetProtectionFilmsStatistics,
    useProtectionFilmTypeQuery,
} from '../api'
import { useFlashMessage } from '../hooks'
import {
    DetailedUsageCounters,
    Loader,
    UsageCounter,
    UsageCounterContainer,
} from '../components/ui'
import PageLayout from '../components/PageLayout'

import type { GenerateQRCodesForm, FormSubmitFn } from '../types'
import { usePolicy } from 'hooks/usePolicy'
import QrGenerator from 'components/QrGenerator'
import moment from 'moment'
import {
    MinusCircleIcon,
    TicketIcon,
    ViewGridIcon,
} from '@heroicons/react/solid'
import { useQueryClient } from 'react-query'

export default function QrGeneratorView() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const flashMessage = useFlashMessage()
    const generateQrCodes = useGenerateQrCodes()
    const downloadQrCodes = useDownloadQRCodes()
    const protectionFilmTypes = useProtectionFilmTypeQuery()
    const protectionFilmsStatistics = useGetProtectionFilmsStatistics()

    const handleSubmit: FormSubmitFn<GenerateQRCodesForm> = (
        values,
        formikHelpers
    ) => {
        generateQrCodes.mutate(
            {
                size: String(values.size?.id!),
                count: values.count,
                protection_film_type_id:
                    typeof values.protection_film_type_id?.id === 'number'
                        ? values.protection_film_type_id?.id
                        : parseInt(values.protection_film_type_id?.id!),
                custom_pressure:
                    typeof values.custom_pressure?.id === 'number'
                        ? values.custom_pressure?.id
                        : parseInt(values.custom_pressure?.id!),
                custom_speed:
                    typeof values.custom_speed?.id === 'number'
                        ? values.custom_speed?.id
                        : parseInt(values.custom_speed?.id!),
            },
            {
                onSuccess: async () => {
                    downloadQrCodes.mutate(
                        { count: values.count },
                        {
                            onSuccess: async (response) => {
                                const href = URL.createObjectURL(response.data)

                                // create "a" HTML element with href to file & click
                                const link = document.createElement('a')
                                link.href = href
                                link.setAttribute(
                                    'download',
                                    `qr-codes-${moment(new Date()).format(
                                        'DD-MM-yyyy'
                                    )}.csv`
                                ) //or any other extension
                                document.body.appendChild(link)
                                link.click()

                                // clean up "a" element & remove ObjectURL
                                document.body.removeChild(link)
                                URL.revokeObjectURL(href)

                                queryClient.invalidateQueries([
                                    QUERY_CACHE_PROTECTION_FILMS_KEY,
                                ])
                            },
                        }
                    )
                    flashMessage({
                        type: 'success',
                        content: t('info.qr.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const policy = usePolicy()

    if (!policy.isAdmin()) {
        return <div>Forbidden</div>
    }

    if (protectionFilmsStatistics.isLoading || protectionFilmTypes.isLoading) {
        return <Loader />
    }

    if (protectionFilmTypes.isError) {
        return (
            <div className="text-red-600">
                {protectionFilmTypes.error.message}
            </div>
        )
    }

    if (protectionFilmsStatistics.isError) {
        return (
            <div className="text-red-600">
                {protectionFilmsStatistics.error.message}
            </div>
        )
    }

    if (
        !protectionFilmsStatistics.isSuccess ||
        !protectionFilmTypes.isSuccess
    ) {
        return null
    }

    return (
        <PageLayout title={t('form.qr.title')}>
            <>
                <h5 className="text-gray-200 mt-4 py-6 text-lg leading-8 font-medium border-t border-gray-600">
                    {t('qrgenerator.summary.title')}
                </h5>
                <div className="my-6">
                    <UsageCounterContainer>
                        <UsageCounter
                            Icon={TicketIcon}
                            value={
                                protectionFilmsStatistics.data.data.summary
                                    .for_use
                            }
                            label={t('qrgenerator.summary.for_use')}
                        />
                        <UsageCounter
                            Icon={MinusCircleIcon}
                            value={
                                protectionFilmsStatistics.data.data.summary.used
                            }
                            label={t('qrgenerator.summary.used')}
                        />
                        <UsageCounter
                            Icon={ViewGridIcon}
                            value={
                                protectionFilmsStatistics.data.data.summary
                                    .total_count
                            }
                            label={t('qrgenerator.summary.total_count')}
                        />
                    </UsageCounterContainer>
                </div>
                <div className="mb-12">
                    <DetailedUsageCounters
                        protectionFilmsSizes={
                            protectionFilmsStatistics.data.data
                                .protection_films_sizes
                        }
                        protectionFilmsTypes={
                            protectionFilmsStatistics.data.data
                                .protection_films_types
                        }
                    />
                </div>
                <div className="mt-8">
                    <QrGenerator onSubmit={handleSubmit} />
                </div>
            </>
        </PageLayout>
    )
}
