import React from 'react'
import { Outlet } from 'react-router-dom'

import { ReactComponent as Logo } from '../assets/logo.svg'
import { ReactComponent as Pattern } from '../assets/pattern.svg'

export default function LoginLayout() {
    return (
        <div className="relative">
            <div className="absolute top-12 right-[-175px] sm:right-[-125px] md:right-[-85px] lg:right-0">
                <Pattern />
            </div>
            <div className="inline-flex flex-col md:pb-32 pt-24 md:pt-64 items-center gap-12 mx-auto w-full">
                <div className="">
                    <h2 className="">
                        <Logo />
                    </h2>
                </div>
                <div className="w-full max-w-[296px] lg:max-w-[576px]">
                    <div className="w-full max-w-[296px] lg:max-w-[576px]">
                        <Outlet />
                    </div>
                </div>
            </div>
            <div className="absolute top-[510px] md:top-[460px] left-[-150px] sm:left-[-120px] md:left-[-80px] lg:left-0">
                <Pattern />
            </div>
        </div>
    )
}
