import React, { useEffect, useState } from 'react'
import moment from 'moment'
import InputMask from 'react-input-mask'
import classNames from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/solid'

import { getCxFromStyles } from '../../../../../helpers'
import { CommonDatePickerInputProps } from '../interfaces'

import styles from './DatePickerInput.module.scss'
import stylesInputText from 'components/ui/InputText.module.scss'

export interface DatePickerInputProps
    extends Omit<CommonDatePickerInputProps, 'value' | 'onSelect'> {
    value?: Date
    placeholder?: string
    onSelect: (date?: Date, closeDatePicker?: boolean) => void
}

const DatePickerInput = React.forwardRef<
    HTMLDivElement | null,
    DatePickerInputProps
>(
    (
        {
            placeholder,
            value,
            onClick,
            hasError,
            active,
            onSelect,
            disabled,
            ...props
        },
        popperRef
    ) => {
        const cx = getCxFromStyles(styles)
        const inputTextCx = getCxFromStyles(stylesInputText)

        const [localValue, setLocalValue] = useState(() => {
            return value ? moment(value).format('DD.MM.YYYY') : ''
        })

        useEffect(() => {
            setLocalValue(value ? moment(value).format('DD.MM.YYYY') : '')
        }, [value])

        return (
            <>
                <div
                    ref={popperRef}
                    className={cx('root', { active, disabled, hasError })}
                >
                    <InputMask
                        type="text"
                        className={classNames(
                            inputTextCx('root', 'md', {
                                error: hasError,
                                disabled,
                            }),
                            cx('input')
                        )}
                        mask="99.99.9999"
                        onChange={(e) => {
                            const targetValue = e.target.value

                            setLocalValue(targetValue)

                            if (
                                moment(
                                    targetValue,
                                    'DD.MM.YYYY',
                                    true
                                ).isValid()
                            ) {
                                onSelect(
                                    moment(targetValue, 'DD.MM.YYYY').toDate()
                                )
                            }
                        }}
                        onBlur={(e) => {
                            if (
                                !moment(
                                    e.target.value,
                                    'DD.MM.YYYY',
                                    true
                                ).isValid()
                            ) {
                                onSelect(undefined, false)
                                setLocalValue('')
                            }
                        }}
                        onClick={onClick}
                        placeholder={placeholder}
                        value={localValue}
                        disabled={disabled}
                        {...props}
                    />
                    {!disabled && (
                        <div className={styles.chevronIcon}>
                            <ChevronDownIcon className="h-5 w-5 text-gray-100" />
                        </div>
                    )}
                </div>
            </>
        )
    }
)

export default DatePickerInput
