import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TabPanel } from '@headlessui/react'
import { Formik } from 'formik'
import { useQueryClient } from 'react-query'

import {
    QUERY_CACHE_PLOTTERS_KEY,
    QUERY_CACHE_USERS_KEY,
} from '../constants/index'
import { useUpdateUser, useUserByIdQuery } from '../api'
import {
    useFlashMessage,
    useModal,
    useSupervisorsDropdown,
    useUserRoles,
} from '../hooks'
import {
    usePayloadFromUserForm,
    useUserFormGetInitialValues,
} from '../hooks/form'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useActivePlotterForm } from '../hooks/forms/usePlotterActiveForm'
import { usePlotterChangeUserForm } from '../hooks/forms/usePlotterChangeUserForm'

import type { UserForm, FormSubmitFn, AssignPlotterForm } from '../types'
import { UpdateUserRequest } from '../api/types'

import { Loader } from '../components/ui'
import PageLayout from '../components/PageLayout'
import User from '../components/User'
import UserTabView from '../components/UserTabView'
import UserFeatures from '../components/UserFeatures'
import { LANGUAGES } from '../constants/languages'
import SupervisorUsers from '../components/SupervisorUsers'
import SelectPlotterModal from '../components/SelectPlotterModal'

export default function EditUserView() {
    const { t } = useTranslation()

    const { id } = useParams()

    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const navigate = useNavigate()
    const updateUserQuery = useUpdateUser({
        onSuccess: async () => {},
    })
    const userQuery = useUserByIdQuery(id!, {
        enabled: !!id,
    })
    const userData = userQuery.data?.data
    const profileData = useCurrentUser()
    const userRoles = useUserRoles()

    const userDropdownValues = useSupervisorsDropdown()
    const plotterModal = useModal()

    const initialValues: UserForm = useUserFormGetInitialValues(id!)

    const activePlotterForm = useActivePlotterForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_USERS_KEY, id])
    })
    const changeUserPlotterForm = usePlotterChangeUserForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_USERS_KEY, id])
        queryClient.invalidateQueries([QUERY_CACHE_PLOTTERS_KEY, 'index'])
    })

    const payloadFromUserForm = usePayloadFromUserForm<UpdateUserRequest>()
    const handleSubmit: FormSubmitFn<UserForm> = (values, formikHelpers) => {
        updateUserQuery.mutate(
            {
                id: id!,
                data: payloadFromUserForm(values),
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.user.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    navigate('/user')
                    queryClient.removeQueries([QUERY_CACHE_USERS_KEY])
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_USERS_KEY,
                        'index',
                    ])
                    if (String(profileData.data?.data.id) === id) {
                        await queryClient.invalidateQueries(['profile'])
                    }
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitPlotter: FormSubmitFn<AssignPlotterForm> = (
        values,
        formikHelpers
    ) => {
        updateUserQuery.mutate(
            {
                id: id!,
                data: {
                    cutters: {
                        attach: values.attach.map((val) => ({ id: val.id })),
                    },
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.user.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_USERS_KEY,
                        id,
                    ])
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_PLOTTERS_KEY,
                        'index',
                    ])
                    plotterModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    if (userQuery.isLoading) {
        return <Loader />
    }

    if (userQuery.isError) {
        return <div className="text-red-600">{userQuery.error.message}</div>
    }

    if (!userQuery.isSuccess) {
        return null
    }

    const title =
        userData?.first_name && userData?.last_name
            ? `${userData?.first_name} ${userData?.last_name}`
            : userData?.name

    return (
        <PageLayout
            title={title}
            breadCrumbs={[
                {
                    name: t('bread.user_list'),
                    url: '/user',
                },
                {
                    name: title || '',
                },
            ]}
        >
            <>
                <Formik<UserForm>
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, isSubmitting }) => (
                        <UserTabView
                            onAction={
                                userData?.role?.id === userRoles.user.id
                                    ? plotterModal.openModal
                                    : undefined
                            }
                            haveUsers={
                                userData?.role?.id === userRoles.supervisor.id
                            }
                        >
                            <TabPanel>
                                <User
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    isSubmitting={isSubmitting}
                                    supervisors={userDropdownValues}
                                    languages={LANGUAGES}
                                    plottersList={userData?.cutters || []}
                                    activePlotterForm={activePlotterForm}
                                    changeUserPlotterForm={
                                        changeUserPlotterForm
                                    }
                                />
                            </TabPanel>
                            <TabPanel>
                                <UserFeatures
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    isSubmitting={isSubmitting}
                                />
                            </TabPanel>
                            <TabPanel>
                                <SupervisorUsers id={id!} />
                            </TabPanel>
                        </UserTabView>
                    )}
                </Formik>
                <SelectPlotterModal
                    modal={plotterModal}
                    onSubmit={handleSubmitPlotter}
                />
            </>
        </PageLayout>
    )
}
