import { useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import { getCxFromStyles } from '../helpers'
import { useSidebarItems } from '../hooks/useSidebarItems'

import { useGetUser } from '../api'
import { useAuth } from '../hooks'

import styles from './Sidebar.module.scss'

import type { Item, SubItem } from '../hooks/useSidebarItems'

import { ReactComponent as Logo } from '../assets/logo.svg'

interface SidebarProps {
    isSidebarOpen: boolean
    setSidebarOpen: (state: boolean) => void
}

const Sidebar = ({ isSidebarOpen, setSidebarOpen }: SidebarProps) => {
    let location = useLocation()
    const cx = getCxFromStyles(styles)

    const sidebarItems = useSidebarItems()

    const auth = useAuth()
    const user = useGetUser({ enabled: auth.isSignedIn })
    const userData = user.data?.data

    const isItemActive = useCallback(
        (item: SubItem) =>
            item.matches
                ? item.exact === true
                    ? item.matches.filter(
                          (match: string) => match === location.pathname
                      ).length > 0
                    : item.matches.filter((match: string) =>
                          location.pathname.startsWith(match)
                      ).length > 0
                : item.exact === true
                  ? item.to === location.pathname
                  : item.exclude
                    ? location.pathname.startsWith(item.to) &&
                      !item.exclude.includes(location.pathname)
                    : location.pathname.startsWith(item.to),
        [location]
    )

    const { t } = useTranslation()

    return (
        <div
            id="main-layout-sidebar"
            className={cx('root', 'print:hidden', 'fixed lg:w-64', {
                isExpanded: isSidebarOpen,
            })}
        >
            <header>
                <div className={cx('header', 'fixed lg:static')}>
                    <div className={cx('logo')}>
                        <span>
                            <Logo className="max-h-8" />
                        </span>
                    </div>
                    <div className="lg:hidden">
                        <button
                            className={cx('menuExpander')}
                            onClick={() => setSidebarOpen(!isSidebarOpen)}
                        >
                            <span className={cx('w-8 h-8')}>
                                {isSidebarOpen ? <XIcon /> : <MenuIcon />}
                            </span>
                        </button>
                    </div>
                </div>
            </header>

            <nav className={cx('nav', 'lg:overflow-y-visible')}>
                {sidebarItems.map((item) => {
                    const active = isItemActive(item)

                    return (
                        <div
                            key={item.name}
                            className={cx('item-container', 'space-y-1')}
                        >
                            <Link
                                to={item.to}
                                className={cx('navItem', {
                                    active,
                                    disabled: item.disabled,
                                })}
                                onClick={() => setSidebarOpen(false)}
                            >
                                {renderMenuItem({
                                    item,
                                })}
                            </Link>
                        </div>
                    )
                })}
            </nav>
            <div className={cx('info', 'pt-5 px-4 pb-4')}>
                <div className="inline-block w-full">
                    <Link
                        to="profile"
                        className="text-base leading-6 font-medium"
                        onClick={() => setSidebarOpen(false)}
                    >
                        {userData?.first_name && userData?.last_name
                            ? `${userData?.first_name} ${userData?.last_name}`
                            : (userData?.name ?? userData?.email)}
                    </Link>
                </div>
                <Link
                    to="/logout"
                    className="text-sm leading-5 font-medium text-red-600"
                    onClick={() => setSidebarOpen(false)}
                >
                    {t('auth.logout')}
                </Link>
            </div>
        </div>
    )

    function renderMenuItem({ item }: { item: Item }) {
        return (
            <>
                <span className={cx('icon-container')}>
                    {item.icon ? <item.icon className={cx('icon')} /> : null}
                </span>
                <span className={cx('title')}>{item.name}</span>
            </>
        )
    }
}

export default Sidebar
