import React from 'react'
import { useField } from 'formik'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

import { Button } from '../ui'
import ErrorMessage from './ErrorMessage'

export default function CounterField({
    name,
    showErrorMessage = true,
}: {
    name: string
    trailingAddOn?: string
    showErrorMessage?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>) {
    // eslint-disable-next-line
    const [field, _meta, helpers] = useField(name)

    return (
        <>
            <div className="relative">
                <span className="px-3 py-2 block w-full bg-gray-500 border border-gray-800 shadow-sm rounded-lg text-center text-sm">
                    {field.value}
                </span>
                <Button
                    type="button"
                    variant="tertiary"
                    className="absolute left-0 top-0"
                    onClick={() =>
                        helpers.setValue(parseInt(field.value, 10) - 1)
                    }
                >
                    <ChevronDownIcon className="w-5 h-5" />
                </Button>
                <Button
                    type="button"
                    variant="tertiary"
                    className="absolute right-0 top-0"
                    onClick={() =>
                        helpers.setValue(parseInt(field.value, 10) + 1)
                    }
                >
                    <ChevronUpIcon className="w-5 h-5" />
                </Button>
            </div>
            {showErrorMessage && <ErrorMessage name={field.name} />}
        </>
    )
}
