import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TabPanel } from '@headlessui/react'
import { Formik } from 'formik'

import { QUERY_CACHE_USERS_KEY } from '../constants/index'
import { useCreateUser } from '../api'
import { useFlashMessage, useSupervisorsDropdown } from '../hooks'
import PageLayout from '../components/PageLayout'
import User from '../components/User'
import UserTabView from '../components/UserTabView'
import UserFeatures from '../components/UserFeatures'
import { LANGUAGES } from '../constants/languages'

import type { UserForm, FormSubmitFn } from '../types'
import { useQueryClient } from 'react-query'
import { usePayloadFromUserForm, useUserInitialValues } from '../hooks/form'
import { CreateUserRequest } from '../api/types'

export default function NewUserView() {
    const { t } = useTranslation()

    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const navigate = useNavigate()
    const createUserQuery = useCreateUser({
        onSuccess: async () => {},
    })

    const userDropdownValues = useSupervisorsDropdown()
    const initialValues: UserForm = useUserInitialValues()

    const payloadFromUserForm = usePayloadFromUserForm<CreateUserRequest>()
    const handleSubmit: FormSubmitFn<UserForm> = (values, formikHelpers) => {
        createUserQuery.mutate(
            {
                data: payloadFromUserForm(values),
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.user.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    navigate('/user')
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_USERS_KEY,
                        'index',
                    ])
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    return (
        <PageLayout
            title={t('form.user.new.title')}
            breadCrumbs={[
                {
                    name: t('bread.user_list'),
                    url: '/user',
                },
                {
                    name: t('form.user.new.title'),
                },
            ]}
        >
            <Formik<UserForm>
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, isSubmitting }) => (
                    <UserTabView haveUsers={false}>
                        <TabPanel>
                            <User
                                values={values}
                                setFieldValue={setFieldValue}
                                isSubmitting={isSubmitting}
                                supervisors={userDropdownValues}
                                languages={LANGUAGES}
                                plottersList={[]}
                            />
                        </TabPanel>
                        <TabPanel>
                            <UserFeatures
                                values={values}
                                setFieldValue={setFieldValue}
                                isSubmitting={isSubmitting}
                            />
                        </TabPanel>
                        <TabPanel>{/* <SupervisorUsers /> */}</TabPanel>
                    </UserTabView>
                )}
            </Formik>
        </PageLayout>
    )
}
