import React, { Fragment } from 'react'
import {
    Dialog,
    DialogPanel,
    Transition,
    TransitionChild,
} from '@headlessui/react'

import { IModal } from '../types'
import classNames from 'classnames'

export const MODAL_TRANSITION_LEAVE_DURATION = 200

const Modal = React.forwardRef<
    HTMLDivElement | null,
    {
        children: React.ReactNode
        modal: IModal
        type: 'default' | 'warning'
        onClickOutside?: () => void
        className?: string
        initialFocus?: React.MutableRefObject<any>
    }
>(({ children, modal, type, className, initialFocus, onClickOutside }, ref) => {
    return (
        <Transition show={modal.isOpen} as={Fragment}>
            <Dialog open={modal.isOpen} onClose={modal.closeModal}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className="fixed inset-0 bg-[#201F1F]/80 z-20"
                        aria-hidden="true"
                    />
                </TransitionChild>

                <div className="fixed inset-0 w-screen overflow-y-auto z-20">
                    <div className="flex min-h-full items-end justify-center p-4 items-center sm:p-0">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel
                                className={classNames(
                                    'relative transform rounded-lg bg-background p-6 border-2 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6',
                                    className,
                                    {
                                        'border-gray-600': type === 'default',
                                        'border-red-600': type === 'warning',
                                    }
                                )}
                            >
                                {children}
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
})

export default Modal
