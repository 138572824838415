import { useMutation, useQuery } from 'react-query'
import type {
    UseMutationOptions,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import client from '../client'
import { QUERY_CACHE_PROTECTION_FILM_TYPES_KEY } from '../../constants/index'

import type {
    ResponseList,
    ResponseError,
    ProtectionFilmTypeListResponse,
    CreateProtectionFilmTypeVariables,
    ResponseData,
    ProtectionFilmTypeResponse,
    UpdateProtectionFilmTypeVariables,
    DeleteVariables,
} from '../types'

export const getProtectionFilmType = <T>(filters?: {}): Promise<T> =>
    client.get(
        '/protection-film-types' +
            (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useProtectionFilmTypeQuery = <
    T = ResponseList<ProtectionFilmTypeListResponse>,
>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_PROTECTION_FILM_TYPES_KEY, 'index', filters],
        () => getProtectionFilmType<T>(filters),
        options
    )

const createProtectionFilmType = ({
    data,
}: CreateProtectionFilmTypeVariables): Promise<
    ResponseData<ProtectionFilmTypeResponse>
> => client.post('/protection-film-types', data)

export const useCreateProtectionFilmType = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<ProtectionFilmTypeResponse>,
            ResponseError,
            CreateProtectionFilmTypeVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => createProtectionFilmType(data), options)

const updateProtectionFilmType = ({
    id,
    data,
}: UpdateProtectionFilmTypeVariables): Promise<
    ResponseData<ProtectionFilmTypeResponse>
> => client.patch(`/protection-film-types/${id}`, data)

export const useUpdateProtectionFilmType = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<ProtectionFilmTypeResponse>,
            ResponseError,
            UpdateProtectionFilmTypeVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updateProtectionFilmType(data), options)

const deleteProtectionFilmType = ({
    id,
}: DeleteVariables): Promise<ResponseData<void>> =>
    client.delete(`/protection-film-types/${id}`)

export const useDeleteProtectionFilmType = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => deleteProtectionFilmType(data), options)
