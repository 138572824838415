import { useTranslation } from 'react-i18next'

export const useFeedbackTypes = () => {
    const { t } = useTranslation()

    return [
        { id: 'system', name: t('feedback_type.system') },
        { id: 'cut', name: t('feedback_type.cut') },
        { id: 'apply', name: t('feedback_type.apply') },
        { id: 'other', name: t('feedback_type.other') },
        { id: 'success', name: t('feedback_type.success') },
        { id: 'fail', name: t('feedback_type.fail') },
    ]
}
